import { startGoogleAnalyticsCollection, stopGoogleAnalyticsCollection } from '@/plugins/gtag';
import { updateProfile } from '@/services/api';
import { getCurrentLanguageWithoutWebsite, isUserLoggedIn } from '@/utils/helpers';
import { useState } from '@u3u/vue-hooks';
import { ref } from '@vue/composition-api';
import moment from 'moment';
import { ALLOWED_LANGUAGES } from '@packages/mongodb';

const CookiePolicyIdByLanguage = {
  [ALLOWED_LANGUAGES.en]: 58324678,
  [ALLOWED_LANGUAGES.be]: 12526065,
  [ALLOWED_LANGUAGES.de]: 93214379,
  [ALLOWED_LANGUAGES.ru]: 45001668,
};

const SupportedLanguages = [ALLOWED_LANGUAGES.en, ALLOWED_LANGUAGES.be, ALLOWED_LANGUAGES.de, ALLOWED_LANGUAGES.ru];

const visitorUserLastTimeMeasurementCookiesAccepted = ref(null);

export const useIubenda = () => {
  const onConsentFirstGiven = async () => {
    if (!isUserLoggedIn() && isAllowedToCollectGoogleAnalyticsData() && visitorUserLastTimeMeasurementCookiesAccepted.value === null) {
      visitorUserLastTimeMeasurementCookiesAccepted.value = moment();
    }

    if (isUserLoggedIn() && localAndUserMeasurementCookieNotInSync()) {
      await updateProfile({ hasAllowedMeasurementCookies: isUserAllowedMeasurementCookieLocally() });
    }

    if (!isAllowedToCollectGoogleAnalyticsData()) stopGoogleAnalyticsCollection();
    if (isAllowedToCollectGoogleAnalyticsData()) startGoogleAnalyticsCollection();
  };

  const getCurrentOrDefaultLanguageCookieConf = () => {
    const currentLang = getCurrentLanguageWithoutWebsite();
    return SupportedLanguages.includes(currentLang) ? currentLang : ALLOWED_LANGUAGES.en;
  };

  const initIubendaCookieBanner = () => {
    // main config script
    const configScript = document.createElement('script');

    // consent db facture script
    const consentDbScript = document.createElement('script');
    const stubScriptOne = document.createElement('script');
    const stubScriptTwo = document.createElement('script');
    const stubScriptThree = document.createElement('script');
    const consDbScript = document.createElement('script');
    const csScript = document.createElement('script');

    configScript.type = 'text/javascript';
    configScript.id = 'cookie-banner-script-1';

    stubScriptOne.type = 'text/javascript';
    stubScriptTwo.type = 'text/javascript';
    stubScriptThree.type = 'text/javascript';
    consDbScript.type = 'text/javascript';
    csScript.type = 'text/javascript';

    stubScriptOne.src = '//cs.iubenda.com/sync/2129735.js';
    stubScriptTwo.src = '//cdn.iubenda.com/cs/gpp/stub.js';
    stubScriptThree.src = '//cdn.iubenda.com/cs/iubenda_cs.js';
    consDbScript.src = '//cdn.iubenda.com/cons/iubenda_cons.js';

    stubScriptOne.id = 'cookie-banner-script-2';
    stubScriptTwo.id = 'cookie-banner-script-3';
    stubScriptThree.id = 'cookie-banner-script-4';
    csScript.id = 'cookie-banner-script-5';
    consDbScript.id = 'cookie-banner-script-6';

    csScript.setAttribute('defer', '');
    csScript.setAttribute('charset', 'UTF-8');

    const selectedLanguage = getCurrentOrDefaultLanguageCookieConf();

    /**
     * Below are the configuration for the Iubenda cookie consent banner. Usually we just have to copy and paste the embed code provided by Iubenda.
     * But in our case have manipulated some  of the configurations and added some extra configuration as per our requirements.
     * Manipulated configurations:
      - floatingPreferencesButtonDisplay: false => While running automation the Iubenda icon is showing in bottom left. To hide that this configuration is added.   // The default value of this is false and it is not added in the configuration by default.
      - "lang": currentLang ex: en, he    // Default in Iubenda is 'en' but as we are providing multi language support we are passing current language to set the content in cookie banner in current language
      - "cookiePolicyId": from the 'CookiePolicyIdByLanguage' object. as this id is different for all supported languages. // Default is '58324678' which is for 'English'.
      - "callback": We can pass multiple callbacks here, default and necessary one is 'onConsentFirstGiven'.
        - In Iubenda the 'onConsentFirstGiven' is declared with console.log in it
        - But have have separately declared and written out custom code in side that.
        - Also we have added 'onReady' callback for setting banner HTML once the Iubenda banner is loaded.
        - We have added 'onPreferenceFirstExpressed' for storing consent in consent database of Iubenda
     * Excluded configurations:
      - "i18n" // This is basically used to provide language based custom cookie banner content, But we are excluding it and showing the default content of Iubenda (in multi language).
     * Extra configuration:
      - var bannerHTML; -> Added this variable to store banner HTML so that further we store that in consent database of Iubenda.
     */

    configScript.text = `var _iub = _iub || []; var bannerHTML; _iub.csConfiguration = {"countryDetection":true,"enableRemoteConsent":true,"floatingPreferencesButtonDisplay":false,"enableUspr":true,"lang":"${selectedLanguage}","perPurposeConsent":true,"siteId":2129735,"usprPurposes":"sh","whitelabel":false,"cookiePolicyId":${CookiePolicyIdByLanguage[selectedLanguage]}, "callback":{ "onConsentFirstGiven": onConsentFirstGiven,


    "onReady": function() {
      var banner = document.getElementById('iubenda-cs-banner');
      if (banner) {
        bannerHTML = banner.innerHTML;
      }
    }, "onPreferenceFirstExpressed": function(event) {
      _iub.cons_instructions.push(["submit",
          {
              consent: {
                  subject: {},
                  preferences: event,
                  legal_notices: [{
                      identifier: "cookie_policy"
                  }],
                  proofs: [{
                      content: JSON.stringify(event),
                      form: bannerHTML
                  }]
              }
          },
        ]);
      } },


    "banner":{ "acceptButtonCaptionColor":"#FFFFFF","acceptButtonColor":"#C83E7E","acceptButtonDisplay":true,"backgroundColor":"#FFFFFF","backgroundOverlay":true,"closeButtonDisplay":false,"customizeButtonColor":"#C83E7E","customizeButtonDisplay":true,"explicitWithdrawal":true,"listPurposes":true,"linksColor":"#C83E7E","position":"bottom","rejectButtonCaptionColor":"#C83E7E","rejectButtonColor":"#FFFFFF","rejectButtonDisplay":true,"textColor":"#090909" }};`;

    /**
     * Below is the configuration for the consent DB and that is identical as Iubenda embed configuration of consent DB.
     */
    consentDbScript.text = `var _iub = _iub || {}; _iub.cons_instructions = _iub.cons_instructions || []; _iub.cons_instructions.push(["init", {api_key: "sMiOod3DZyQGrkSYg7TU3MtzJYUVTrLJ"}]);`;

    document.head.appendChild(stubScriptOne);
    document.head.appendChild(stubScriptTwo);
    document.head.appendChild(stubScriptThree);
    document.head.appendChild(consDbScript);
    document.head.appendChild(csScript);
    document.head.appendChild(configScript);
    document.head.appendChild(consentDbScript);
  };

  // setting handler function for the iubenda onConsentFirstGiven callback
  (window as any).onConsentFirstGiven = onConsentFirstGiven;

  const isUserInteractedCookiesSomeTimeAgo = () => {
    if (visitorUserLastTimeMeasurementCookiesAccepted.value === null) return false;
    return moment().diff(visitorUserLastTimeMeasurementCookiesAccepted.value, 'minutes') < 20;
  };

  const setVisitorUserLastTimeMeasurementCookiesAccepted = (time) => {
    visitorUserLastTimeMeasurementCookiesAccepted.value = time;
  };

  const isAllowedToCollectGoogleAnalyticsData = () => {
    if (isUserLoggedIn()) {
      return isUserAllowedMeasurementCookieInPreference();
    }

    if (!isUserLoggedIn()) {
      return isUserAllowedMeasurementCookieLocally();
    }
  };

  const isUserAllowedMeasurementCookieLocally = () => {
    return getIubendaPreferences()?.purposes?.['4'];
  };

  const isUserAllowedMeasurementCookieInPreference = () => {
    const { user } = useState('auth', ['user']);
    return user.value?.preferences?.hasAllowedMeasurementCookies;
  };

  const getIubendaPreferences = () => {
    return (window as any)?._iub?.cs?.api?.getPreferences();
  };

  const localAndUserMeasurementCookieNotInSync = () => {
    return isUserAllowedMeasurementCookieLocally() !== isUserAllowedMeasurementCookieInPreference();
  };

  const showIubendaBanner = () => {
    (window as any)?._iub?.cs?.api?.resetCookies();
    (window as any)?._iub?.cs?.api?.showBanner();
  };

  return {
    initIubendaCookieBanner,
    isAllowedToCollectGoogleAnalyticsData,
    getIubendaPreferences,
    isUserAllowedMeasurementCookieLocally,
    isUserAllowedMeasurementCookieInPreference,
    showIubendaBanner,
    visitorUserLastTimeMeasurementCookiesAccepted,
    isUserInteractedCookiesSomeTimeAgo,
    setVisitorUserLastTimeMeasurementCookiesAccepted,
  };
};

// {
//     success: function(response) {
//         console.log(response);
//         console.log('Consent sent to Consent Solution');
//     },
//     error: function(response) {
//         console.log(response);
//     }
// }
